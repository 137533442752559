<template>
  <div style="text-align: center;">
    <Card id="report" :style="box_style">
      <p slot="title">
        <Icon type="ios-person"></Icon>舉報
      </p>
      <div class="report-content">
        <blockquote v-if="html" v-html="html"></blockquote>
        <blockquote v-else>{{text}}</blockquote>
      </div>

      <div>
        類型：
        <Select v-model="category" filterable clearable prefix="ios-globe" placeholder="Select type" style="width: auto">
          <Option v-for="(v, key) in categories" :value="key" :label="v"
                    :key="key">{{ v }}
          </Option>
        </Select>
      </div>
      <Divider dashed size="small"></Divider>
      <div style="text-align: center;">
        <Button @click="report" type="primary">提交</Button>
      </div>
    </Card>
  </div>
</template>
<script>
  import { httpPost } from '@/api/data';

  export default {
    name: 'Report',
    components: {
    },
    data () {
      return {
        type: '',
        categories: {
          0: '抵毁',
          1: '版权',
          2: '恐怖主义'
        },
        category: 0,
        content: null,
        text: '',
        html: null,

        box_style: ''
      }
    },
    methods: {
      report () {
        httpPost('/v1/bbs/report', {
          type: this.category,
          content: this.content
        }).then(() => {
          this.$Modal.warning({
            title: this.$t('page.report.alert_title'),
            content: this.$t('page.report.alert_content'),
            onOk: () => {
              this.$router.go(-1);
            }
          });
        }).catch(() => {
        });
      }
    },
    created () {
      if (window.screen.width < 730) {
        this.box_style = '';
      } else {
        this.box_style = 'width: 350px; text-align:left; display: inline-block;';
      }
      this.content = this.$route.params.content;
      if (!this.content) {
        this.$router.go(-1);
        return;
      }
      this.type = this.$route.params.type;
      this.html = this.$route.params.html;
      if (!this.html) {
        this.text = this.$route.params.text;
      }
    }
  };
</script>

<style scoped>
  .report-content {
    padding: 2px;
    border: gray dashed 1px;
    color: gray;
    font-size: smaller;
    margin-bottom: 5px;
    width: 100%;
    max-height: 100px;
    overflow: hidden;
  }
</style>
